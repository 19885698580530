import Vue from 'vue'
import Vuex from 'vuex'
import bus from '@/services/bus'
import { get_element_by_id, get_index_by_id, update_or_create } from '@/utils'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    username: 'Anonymous',
    socket_state: false,
    login_next: '/',
    control_map: [],
    host_map: [],
    data_map: [],
    action_map: [],
    chart_map: [],
    entry_map: [],
    lastentry_map: [],
    event_map: [],
    scheduler_map: [],
    scheduler_event_map: [],
    scheduler_metadata: [],
    datatrigger_map: [],
    control_layout: [],
    host_landing_state: false,
    data_map_state: false,
    data_landing_state: false,
    control_landing_state: false,
    chart_landing_state: false,
    action_landing_state: false
  },

  getters: {
    get_socket_state: state => state.socket_state,
    get_username: state => state.username,
    get_control_map: state => state.control_map,
    get_host_map: state => state.host_map,
    get_data_map: state => state.data_map,
    get_chart_map: state => state.chart_map,
    get_lastentry_map: state => state.lastentry_map,
    get_entry_map: state => state.entry_map,
    get_event_map: state => state.event_map,
    get_action_map: state => state.action_map,
    get_control_layout: state => state.control_layout,
    get_host_landing_state: state => state.host_landing_state,
    get_data_landing_state: state => state.data_landing_state,
    get_data_map_state: state => state.data_map_state,
    get_control_landing_state: state => state.control_landing_state,
    get_chart_landing_state: state => state.chart_landing_state,
    get_action_landing_state: state => state.action_landing_state,
    get_scheduler_map: state => state.scheduler_map,
    get_scheduler_event_map: state => state.scheduler_event_map,
    get_scheduler_metadata: state => state.scheduler_metadata,
    get_datatrigger_map: state => state.datatrigger_map
  },

  mutations: {
    set_login_next (state, path) {
      state.login_next = path
    },

    set_socket_state (state, socket_state) {
      state.socket_state = socket_state
      bus.$emit('socket/state', socket_state)
    },

    reset_chart_map (state) {
      state.chart_map = []
    },

    reset_scheduler_event_map (state, scheduler) {
      let scheduler_event_map = get_element_by_id(state.scheduler_event_map, scheduler)
      if (!scheduler_event_map) return
      scheduler_event_map.entries = []
    },

    set_username (state, username) {
      state.username = username
    },

    set_data_map_state () { set_map_state('data', true) },
    set_host_landing_state () { set_landing_state('host', true) },
    set_data_landing_state () { set_landing_state('data', true) },
    set_control_landing_state () { set_landing_state('control', true) },
    set_event_landing_state () { set_landing_state('event', true) },
    set_action_landing_state () { set_landing_state('action', true) },
    set_chart_landing_state (state, response) { set_landing_state('chart', response) },
    set_data (state, response) { set_store(state.data_map, response) },
    set_chart (state, response) { set_store(state.chart_map, response) },
    set_host (state, response) { set_store(state.host_map, response) },
    set_event (state, response) { set_store(state.event_map, response) },
    set_scheduler (state, response) { set_store(state.scheduler_map, response) },
    set_scheduler_metadata (state, response) { state.scheduler_metadata = response.data },
    set_action (state, response) { set_store(state.action_map, response) },
    set_entry (state, response) {
      set_container_store(state.entry_map, response, 'data_pk', ['start'])
    },
    set_scheduler_event (state, response) {
      set_container_store(state.scheduler_event_map, response, 'scheduler')
    },
    set_datatrigger (state, response) { set_store(state.datatrigger_map, response) },

    set_positions (state, payload) {
      for (let element of payload.position_map) {
        let control = get_element_by_id(state[`${payload.attribute}_map`], element.id)
        if (!control) continue
        control.position = element.position
      }
      bus.$emit(`update/${payload.attribute}_map`, null)
    },

    set_control (state, response) {
      let populate = (control) => {
        control.childdata_map = []
        update_or_create(state.control_map, control)
        bus.$emit(`update/control/${control.id}`, control)
        update_or_create(state.data_map, control.data)
        control.data = control.data.id
        bus.$emit(`update/data/${control.data.id}`, control.data)
      }
      if (response.many) {
        for (let control of response.data) populate(control)
        bus.$emit('update/control_map', '')
        return
      }
      if (response.type === 'response') {
        populate(response.data)
        return
      }
      // Push response : data may be incomplete
      let stored_control = get_element_by_id(state.control_map, response.data.id)
      if (!stored_control) {
        populate(response.data)
        bus.$emit('update/control_map', '')
        return
      }
      // update : Only for given properties
      for (let prop of Object.keys(response.data)) {
        if (prop === 'deleted') {
          let index = get_index_by_id(state.control_map, response.data.id)
          if (index >= 0) state.control_map.splice(index, 1)
          break
        }
        if (prop === 'data') {
          update_or_create(state.data_map, response.data[prop])
          stored_control.data = response.data[prop].id
          continue
        }
        stored_control[prop] = response.data[prop]
      }
      bus.$emit('update/control_map', '')
    },

    set_childdata (state, response) {
      let control = get_element_by_id(state.control_map, response.data.id)
      if (!control) {
        console.error('Control not found, id : ' + response.data.id)
        return
      }
      control.childdata_map = []
      for (let data of response.data.childdata_map) {
        data.alarm_value = false
        update_or_create(state.data_map, data)
        control.childdata_map.push(data.id)
      }
      update_or_create(state.control_map, control)
      bus.$emit(`update/control/${control.id}`, control)
    },

    set_lastentry (state, response) {
      let populate = (entry) => {
        if (entry.id === 'empty') {
          bus.$emit(`update/lastentry/${entry.data_pk}`, entry.id)
          return
        }
        entry.id = entry.data_pk
        delete (entry.data_pk)
        update_or_create(state.lastentry_map, entry)
        bus.$emit(`update/lastentry/${entry.id}`, entry)
      }
      if (response.many) {
        for (let entry of response.data) populate(entry)
        return
      }
      populate(response.data)
    }
  }
})

function set_landing_state (name, state) {
  store.state[`${name}_landing_state`] = state
  bus.$emit(`state/${name}_landing`, state)
}

function set_map_state (name, state) {
  store.state[`${name}_map_state`] = state
  bus.$emit(`state/${name}_map`, state)
}

function set_container_store (state, response, ref_pk, props) {
  let commit = (element) => {
    if (element.id === 'error') {
      bus.$emit(`error/${response.name}/${element[ref_pk]}`, element.message)
      return
    }
    let container = get_element_by_id(state, element[ref_pk])
    if (element.id === 'endframe' || element.id === 'empty') {
      if (!container || element.id === 'empty') container = { id: element[ref_pk], entries: [] }
      if (props) {
        for (let prop of props) container[prop] = element[prop]
      }
      update_or_create(state, container)
      bus.$emit(`update/${response.name}_map/${element[ref_pk]}`, null)
      return
    }
    if (!container) {
      container = { id: element[ref_pk], entries: [element] }
      update_or_create(state, container)
    } else {
      update_or_create(container.entries, element)
    }
  }

  if (response.many) {
    for (let element of response.data) commit(element)
    return
  }
  commit(response.data)
}

function set_store (state, response) {
  let commit = (element) => {
    if (element.id === 'error') {
      bus.$emit(`error/${response.name}/${element.id}`, element.message)
      return
    }
    if (element.id === 'endframe') {
      bus.$emit(`update/${response.name}_endframe/${element.pk}`, '')
      return
    }
    if (response.data.hasOwnProperty('deleted')) {
      let index = get_index_by_id(state, response.data.id)
      if (index >= 0) state.splice(index, 1)
      return
    }
    update_or_create(state, element)
    bus.$emit(`update/${response.name}/${element.id}`, element)
    bus.$emit(`update/${response.name}`, element)
  }
  if (response.many) {
    for (let item of response.data) commit(item)
    bus.$emit(`update/${response.name}_map`, 'map')
  } else commit(response.data)
}

export default store
export { update_or_create }
