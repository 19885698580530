import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import ROUTES from '@/routes'
import store from '@/services/store'
import CONF from '@/conf'
import bus from '@/services/bus'

Vue.use(Router)
Vue.use(Meta)

const routes = ROUTES
const router = new Router({
  mode: 'history',
  routes: routes
})

router.beforeEach((to, from, next) => {
  bus.$emit('route/new', null)
  let sessionid_cookie = router.app.$cookie.get(CONF.SESSION_COOKIE)
  if (!sessionid_cookie && (to.path !== '/login')) {
    store.commit('set_login_next', to.path)
    next('/login')
  } else next()
})

export default router
