function hex_to_rgb (hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16)
  let g = parseInt(hex.slice(3, 5), 16)
  let b = parseInt(hex.slice(5, 7), 16)
  return ((alpha) ? 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
    : 'rgb(' + r + ', ' + g + ', ' + b + ')')
}

function LightenDarkenColor (col, amt) {
  let usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }
  let num = parseInt(col, 16)
  let r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00FF) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000FF) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

function get_random_id () {
  return parseInt(Math.random() * 1000000, 10)
}

function get_element_by_id (map, id) {
  for (let element of map) {
    if (`${id}` === `${element.id}`) return element
  }
}

function get_index_by_id (map, id) {
  let index, len
  for (index = 0, len = map.length; index < len; index++) {
    if (map[index]['id'] === id) return index
  }
  return -1
}

function get_host_color (map, id) {
  let host = get_element_by_id(map, id)
  if (host) return host.color
  return '#000'
}

function get_host_name (map, id) {
  let host = get_element_by_id(map, id)
  if (host) return host.name
  return ''
}

function update_or_create (map, object) {
  let index = get_index_by_id(map, object.id)
  if (index >= 0) {
    map.splice(index, 1, object)
  } else {
    map.push(object)
  }
}

export {
  get_random_id, LightenDarkenColor, get_element_by_id, get_index_by_id,
  get_host_color, get_host_name, update_or_create, hex_to_rgb
}
