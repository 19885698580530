const ROOT_NAME = window.location.host
const ROOT_URL = `https://${ROOT_NAME}/api/`

// Set to sessionid if cors, anything but sessionid otherwise
const SESSION_COOKIE = 'session_key'
const SOCKET_URL = `wss://${ROOT_NAME}/ws/`

const ENV_CONF = {
  ROOT_URL,
  SOCKET_URL,
  SESSION_COOKIE
}

export default ENV_CONF
