<template lang="pug">
div.app(id="app")
  vue-headful(title="Ownmesh")
  b-navbar(sticky type="inverse" v-if="logged_in")
    b-nav.brand.pl-2.pr-1.pt-2.pb-1
      a(href="/" v-if="!loading")
        img(src="./assets/ownmesh-logo.svg")
      clip-loader(:loading="loading" size="2.8rem")
    b-navbar-nav
      template(v-for="section in section_map")
        b-nav-item.dropdown(:to="section.path" :class="section.class")
          icon.ml-3.mr-3.section(:name="section.icon")
    b-nav.ml-auto(right)
      b-nav-item-dropdown(right :no-caret="true")
        template(slot="button-content")
          icon.user(name="bars")
        component(v-if="menu_content" v-bind:is="menu_content")
        b-dropdown-item(:href="admin_url" target="_blank")
          b-button.pt-2(variant="default" size="lg")
            icon(name="cogs")
            span.pl-3 Admin
        b-dropdown-item(href="/settings")
          b-button(variant="default" size="lg")
            icon(name="sliders-h")
            span.pl-3 Settings
        b-dropdown-item(href="/logout")
          b-button(variant="default" size="lg")
            icon(name="sign-out-alt")
            span.pl-3 Logout
  div.view
    router-view
</template>

<script>
import CONF from '@/conf'
import ROUTES from '@/routes'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/chart-area'
import 'vue-awesome/icons/wrench'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/sliders-h'
import 'vue-awesome/icons/cogs'
import 'vue-awesome/icons/sign-out-alt'
import 'vue-awesome/icons/calendar'
import 'vue-awesome/icons/database'
import 'vue-awesome/icons/clipboard-list'
import Icon from 'vue-awesome/components/Icon'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import bus from '@/services/bus'
import Control_menu from '@/components/Control_menu.vue'
import Chart_menu from '@/components/Chart_menu.vue'
import Action_menu from '@/components/Action_menu.vue'
import Scheduler_menu from '@/components/Scheduler_menu.vue'

export default {
  name: 'app',
  components: {
    Icon,
    ClipLoader,
    control_menu: Control_menu,
    chart_menu: Chart_menu,
    action_menu: Action_menu,
    scheduler_menu: Scheduler_menu
  },
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  data () {
    return {
      loading: false,
      section_map: [],
      menu_content: null,
      admin_url: CONF.ROOT_URL + 'admin/'
    }
  },

  computed: {
    username: function () { return this.$cookie.get('username') },
    logged_in: function () {
      return !(this.$route.path === '/logout' || !this.$cookie.get(CONF.SESSION_COOKIE))
    }
  },

  watch: {
    '$route' (new_route, unused_old_route) {
      this.menu_content = null
      for (let section of this.section_map) {
        section.class = ''
        if (section.name !== new_route.name) continue
        section.class = 'active'
        this.menu_content = section.menu_content
      }
    }
  },

  created () {
    if (this.$cookie.get('csrftoken')) return
    let request = this.$http.get(CONF.TOKEN_URL)
    request.then(
      response => { this.$cookie.set('csrftoken', response.body.text) },
      response => {
        console.error(`Get token failed: ${response.body.text}`)
        this.$toaster['error'](response.body.text)
      }
    )
  },

  mounted () {
    bus.$on('route/new', () => { this.loading = true })
    bus.$on('route/loading', () => { this.loading = true })
    bus.$on('route/ready', () => { this.loading = false })
    for (let route of ROUTES) {
      if (!route.on_nav_bar) continue
      let path = route.path.replace(':id', 'landing')
      let section = {
        path: path, name: route.name, icon: route.icon,
        class: '', menu_content: route.menu_content
      }
      if (route.name === this.$route.name) {
        section.class = 'active'
        this.menu_content = route.menu_content
      }
      this.section_map.push(section)
    }
  },

  beforeDestroy () {
    bus.$off('socket/state')
    bus.$off('route/loading')
    bus.$off('route/ready')
  },

  methods: {
    set_active_section: function (path_name) {
      for (let section of this.section_map) {
        section.class = (section.name === path_name) ? 'active' : ''
      }
    }
  },

}
</script>

<style lang="scss">

@import 'assets/colors';
@import 'assets/fonts';

@font-face {
  font-family: 'OpenSans';
  font-weight: normal;
  src: url('./assets/fonts/OpenSans-Regular.woff');
}

.app {
  > .navbar {
    background: $black;
    border-bottom: 2px solid $bg_body;
    color: $bg_body;
    font-weight: bold;
    padding: 0;

    &,
    > * {
      flex-direction: row;
    }

    .fa-icon {
      color: ligthen($bg_body, 10);
      width: auto;
    }

    .nav-link .fa-icon {
      height: 2rem;
    }

    .fa-icon.section {
      height: 2.7rem;
    }

    .fa-icon.user {
      height: 3rem;
    }

    .brand {
      img,
      .v-spinner {
        opacity: .8;
        width: 6rem;
      }
    }

    .v-spinner {
      .v-clip {
        border-color: lighten($bg_body, 20) lighten($bg_body, 30)  transparent !important;
      }
    }

    a,
    button,
    .section {
      background-color: $black;
      color: lighten($bg_body, 20);
      font-weight: bold;
      outline: none;
      &:hover {
        svg {
          color: lighten($bg_body, 20);
        }
      }
    }


    .dropdown-menu {
      background-color: $black;
      border: 1px solid $black;
      border-radius: .15rem;
      color: $white !important;
      // padding: 1rem;
      top: 95%;

      &.dropdown-menu-right {
        left: auto;
        right: 0;
      }

      .section {
        font-size: 1.7rem;
        padding-bottom: 1rem;
        padding-left: 1.7rem;
        // padding: 0;
      }

      .btn {
        border-radius: 0;
        cursor: pointer;
        font-size: 1.7rem;
        padding: 1rem 1.2rem;
        text-align: left;
        width: 100%;
        &:hover {
          color: $white;
        }
      }

      .submenu {
        font-size: 1.4rem;
        font-weight: normal;
        padding: .75rem 2.4rem;
        &.active {
          color: $white;
          font-weight: bold;
        }
      }

      .dropdown-item {
        padding: 3px .5rem;
      }
    }

    .active {
      a,
      button {
        &::before {
          border: 0 solid transparent;
          border-bottom-color: $bg_body;
          border-width: .7rem;
          bottom: -2px;
          content: ' ';
          display: block;
          height: 0;
          left: 50%;
          pointer-events: none;
          position: absolute;
          transform: translateX(-50%);
          width: 0;
          z-index: 100;
        }
        svg {
          color: lighten($bg_body, 20);
        }
      }
    }
    span {
      &.d-inline-block {
        height: 100%;
      }
      li {
        height: inherit;
      }
    }
    .username {
      text-transform: capitalize;
    }
  }
  > .view {
    .navbar {
      background: $navbar_bgcolor;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      color: $navbar_fgcolor !important;
      font-size: 130%;
      font-weight: bold;
      line-height: .8;
      padding: 5px 0;
      .main_label {
        padding-left: 8px !important;
        padding-right: 8px !important;
        > * {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}
</style>
