<template lang="pug">
div
  div.section
    icon(name="chart-area")
    span.pl-3 Charts
  b-dropdown-item(v-show="landing")
    b-button.submenu(variant="default" @click="show_chart_order_modal")
      icon(name="th")
      span.pl-3 Set positions
  b-dropdown-item(v-show="landing")
    b-button.submenu(variant="default" @click="add_chart")
      icon(name="plus")
      span.pl-3 Add chart
  b-dropdown-item(v-show="!landing")
    b-button.submenu(variant="default" @click="add_chart")
      icon(name="plus")
      span.pl-3 Add to favorites
</template>

<script>
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/chart-area'
import 'vue-awesome/icons/th'
import 'vue-awesome/icons/plus'
export default ({
  name: 'Chart_menu',
  components: {
    Icon
  },
  data () {
    return {
      landing: false
    }
  },
  methods: {
    show_chart_order_modal: function () {
      bus.$emit('show_chart_order_modal', null)
    },
    add_chart: function () {
      bus.$emit('add_chart', null)
    }
  },

  mounted () {
    bus.$on('route/chart_landing', (state) => { this.landing = state })
  },
  beforeDestroy: function () {
    bus.$off('route/chart_landing')
  }
})
</script>

<style lang="scss" scoped>
</style>
