<template lang="pug">
div
  div.section
    icon(name="calendar")
    span.pl-3 Schedulers
  b-dropdown-item
    b-button.submenu(variant="default" @click="show_scheduler_config_modal()")
      icon(name="cog")
      span.pl-3 Settings
  b-dropdown-item
    b-button.submenu(variant="default" @click="show_scheduler_add_modal()")
      icon(name="plus")
      span.pl-3 New scheduler
</template>

<script>
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/calendar'
import 'vue-awesome/icons/cog'
import 'vue-awesome/icons/plus'
export default ({
  name: 'Chart_menu',
  components: {
    Icon
  },
  data () {
    return {
      landing: false
    }
  },
  methods: {
    show_scheduler_config_modal: function () {
      bus.$emit('show_scheduler_config_modal', null)
    },
    show_scheduler_add_modal: function () {
      bus.$emit('show_scheduler_add_modal', null)
    }
  },

  mounted () {
    bus.$on('route/chart_landing', (state) => { this.landing = state })
  },
  beforeDestroy: function () {
    bus.$off('route/chart_landing')
  }
})
</script>

<style lang="scss" scoped>
</style>
