import ENV_CONF from '@/env.conf'

const ROOT_URL = ENV_CONF.ROOT_URL
const SESSION_COOKIE = ENV_CONF.SESSION_COOKIE
const SOCKET_URL = ENV_CONF.SOCKET_URL

const LOGIN_URL = ROOT_URL + 'auth/login/'
const LOGOUT_URL = ROOT_URL + 'auth/logout/'
const TEST_URL = ROOT_URL + 'test/'
const ENTRY_URL = ROOT_URL + 'entry/'
const CONTROL_POSITIONS_URL = ROOT_URL + 'control/positions/'
const CONTROL_SAVE_URL = ROOT_URL + 'control/save/'
const CONTROL_DELETE_URL = ROOT_URL + 'control/delete/'
const CHART_POSITIONS_URL = ROOT_URL + 'chart/positions/'
const CHART_ADD_URL = ROOT_URL + 'chart/add/'
const CHART_REMOVE_URL = ROOT_URL + 'chart/remove/'
const CHART_HEIGHT_URL = ROOT_URL + 'chart/height/'
const SCHEDULER_EVENT_URL = ROOT_URL + 'scheduler/event/'
const SCHEDULER_CONFIG_URL = ROOT_URL + 'scheduler/config/'
const TOKEN_URL = ROOT_URL + 'auth/csrftoken/'
const MIN_CONTROL_WIDTH = 190
const REQUEST_OPTIONS = { emulateJSON: true, timeout: 15000 }

const CONF = {
  ROOT_URL,
  LOGIN_URL,
  SOCKET_URL,
  TOKEN_URL,
  LOGOUT_URL,
  TEST_URL,
  ENTRY_URL,
  CONTROL_POSITIONS_URL,
  CONTROL_SAVE_URL,
  CONTROL_DELETE_URL,
  CHART_POSITIONS_URL,
  CHART_ADD_URL,
  CHART_REMOVE_URL,
  CHART_HEIGHT_URL,
  MIN_CONTROL_WIDTH,
  SCHEDULER_EVENT_URL,
  SCHEDULER_CONFIG_URL,
  SESSION_COOKIE,
  REQUEST_OPTIONS
}

export default CONF
