<template lang="pug">
div
  div.section Action
  b-dropdown-item
    b-button.submenu(variant="default" @click="add_action")
      icon(name="plus")
      span.pl-3 Add action
</template>

<script>
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/plus'
export default ({
  name: 'Action_menu',
  components: {
    Icon
  },
  data () {
    return {}
  },
  methods: {
    add_action: function () {
      bus.$emit('add_action', null)
    }
  },
})
</script>

<style lang="scss" scoped>
</style>
