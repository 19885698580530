// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuex from 'vuex'
import vueResource from 'vue-resource'
import vueCookie from 'vue-cookie'
import App from './App'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import Toaster from 'v-toaster'
import apiSock from '@/services/api_sock'
import store from '@/services/store'
import vueHeadful from 'vue-headful'

Vue.component('vue-headful', vueHeadful)

Vue.config.productionTip = true
Vue.use(vueResource)
Vue.use(vueCookie)
Vue.use(Vuex)
Vue.use(apiSock, store)
Vue.use(BootstrapVue)
Vue.use(Toaster, {timeout: 5000})
/* eslint-disable no-undef */
window.jQuery = window.$ = require('jquery')

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'v-toaster/dist/v-toaster.css'
import '@/assets/globals.scss'
import '@/assets/modal.scss'
import '@/assets/switcher.scss'
import '@/assets/animate.css'
import '@/assets/toaster.scss'

Vue.http.interceptors.push(function (request, next) {
  // Add csrftoken & session id to headers if found
  let csrftoken_cookie = this.$cookie.get('csrftoken')
  if (csrftoken_cookie) {
    request.headers.set('x-csrftoken', csrftoken_cookie)
  }
  let sessionid_cookie = this.$cookie.get('sessionid')
  if (sessionid_cookie) {
    request.headers.set('sessionid', sessionid_cookie)
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
