const Control_landing = () => import('@/components/Control_landing')
const Data_landing = () => import('@/components/Data_landing')
const Action_landing = () => import('@/components/Action_landing')
const Chart_landing = () => import('@/components/Chart_landing')
const Schedulers = () => import('@/components/Scheduler_landing')
const Test_Widgets = () => import('@/components/Test_Widgets')
const Login = () => import('@/components/Login')
const Logout = () => import('@/components/Logout')

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    on_nav_bar: false
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    on_nav_bar: false
  },
  {
    path: '/',
    name: 'Controls',
    component: Control_landing,
    on_nav_bar: true,
    icon: 'home',
    menu_content: 'control_menu'
  },
  {
    path: '/datas',
    name: 'Datas',
    component: Data_landing,
    on_nav_bar: true,
    icon: 'database',
    menu_content: null
  },
  {
    path: '/charts/:id',
    name: 'Charts',
    component: Chart_landing,
    on_nav_bar: true,
    icon: 'chart-area',
    menu_content: 'chart_menu'
  },
  {
    path: '/schedulers/:id',
    name: 'Schedulers',
    component: Schedulers,
    on_nav_bar: true,
    icon: 'calendar',
    menu_content: 'scheduler_menu'
  },
  {
    path: '/actions',
    name: 'Actions',
    component: Action_landing,
    on_nav_bar: true,
    icon: 'clipboard-list',
    menu_content: 'action_menu'
  },
  {
    path: '/test_widgets',
    name: 'Widgets test',
    component: Test_Widgets,
    on_nav_bar: true,
    icon: 'wrench',
    menu_content: null
  }
]
