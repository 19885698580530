import CONF from '@/conf'
import { WebSocketBridge } from 'django-channels'

export default {
  install (Vue, store) {
    if (!store) throw new Error('Store instance needed !')
    this.ready = false
    this.store = store
    this.vue = Vue.prototype
    Vue.prototype.$socket = this
  },

  connect () {
    if (this.ready) {
      setTimeout(() => { this.store.commit('set_socket_state', true) }, 100)
      return
    }
    this.Socket = new WebSocketBridge()
    this.Socket.addEventListener('open', () => {
      // if (this.ready) return
      console.log(`Connected to WebSocket, session ${session_key}`)
      this.ready = true
      this.store.commit('set_socket_state', true)
    })

    this.Socket.addEventListener('close', () => {
      console.log('WebSocket closed')
    })

    this.Socket.addEventListener('message', (event) => {
      let payload = event.data
      this.store.commit(`set_${payload.name}`, payload)
    })

    let session_key = this.vue.$cookie.get(CONF.SESSION_COOKIE)
    if (!session_key) throw new Error('No session key !')
    this.Socket.connect(`${CONF.SOCKET_URL}?${session_key}`, null, {minReconnectionDelay: 100})
    console.log('Connecting socket done')
  },

  disconnect () {
    console.log('Disconnecting socket ')
    for (let event of ['open', 'close', 'message']) {
      this.Socket.removeEventListener(event)
    }
    this.Socket.close()
    this.Socket = null
    this.ready = false
  },

  send (request_name, data='') {
    if (!this.ready) throw new Error('Socket not ready !')
    console.log('Request for ' + request_name)
    this.Socket.send({
      genre: 'request',
      name: request_name,
      id: Math.floor((Math.random() * 10000) + 1),
      data: data
    })
  }
}
