<template lang="pug">
div
  div.section
    icon(name="home")
    span.pl-3 Controls
  b-dropdown-item
    b-button.submenu(variant="default" @click="show_control_order_modal")
      icon(name="th")
      span.pl-3 Set positions
  b-dropdown-item
    b-button.submenu(variant="default" @click="show_control_add_modal")
      icon(name="plus")
      span.pl-3 New control
  div.section.pt-4
    icon(name="newspaper")
    span.pl-3 Events
  b-dropdown-item(v-for="event_filter in event_filter_map" :key="event_filter.name")
    b-button.submenu(@click="set_event_filter(event_filter)" variant="default"
                     v-bind:class="event_filter_class(event_filter)")
                     | Show {{ event_filter.label }}
</template>

<script>
import bus from '@/services/bus'
import Icon from 'vue-awesome/components/Icon'
import 'vue-awesome/icons/home'
import 'vue-awesome/icons/newspaper'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/th'
export default ({
  name: 'Control_menu',
  components: {
    Icon
  },
  data () {
    return {
      event_filter_active: null,
      event_filter_map: [
        {name: 'AL', label: 'All'}, {name: 'AM', label: 'Alarms'},
        {name: 'DT', label: 'Datas'}, {name: 'NT', label: 'Net status'}
      ]
    }
  },
  methods: {
    show_control_order_modal: function () {
      bus.$emit('show_control_order_modal', null)
    },
    show_control_add_modal: function () {
      bus.$emit('show_control_add_modal', true)
    },
    set_event_filter: function (event_filter) {
      // console.log('event filter change : ' + event_filter.label)
      this.event_filter_active = event_filter
      bus.$emit('event_filter', event_filter.name)
    },
    event_filter_class: function (event_filter) {
      if (!this.event_filter_active) return ''
      return (event_filter.name === this.event_filter_active.name) ? 'active' : ''
    }
  },

  mounted () {
    this.event_filter_active = this.event_filter_map[0]
  }
})
</script>

<style lang="scss" scoped>
</style>
